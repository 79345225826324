import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { isArrayValueNotEmpty, isValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { AssignableTourOperatorContract } from '@/modules/tour-operator-details/api/single-inventory-management.service';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface MoveSingleInventoryManagementSrcLAFPayload
  extends MoveSingleInventoryManagementSrcLAFFieldPayload,
    BaseFlightActionPayload {
  cabinCode: CabinCode | undefined;
}

export interface MoveSingleInventoryManagementSrcLAFFieldPayload {
  tourOperators: AssignableTourOperatorContract[];
  value: number | undefined;
}

export class MoveSingleInventoryManagementSrcLAFAction implements FlightAction<MoveSingleInventoryManagementSrcLAFPayload> {
  private readonly directionIsUp: boolean;
  public actionType: FlightActionType;
  public value: MoveSingleInventoryManagementSrcLAFFieldPayload = {
    tourOperators: [],
    value: undefined,
  };
  public label: string;
  public cabinCode: CabinCode | undefined;
  public fieldType: FilterFieldField.moveSrcLafUp | FilterFieldField.moveSrcLafDown;

  constructor(fieldType: FilterFieldField.moveSrcLafUp | FilterFieldField.moveSrcLafDown, cabinCode?: CabinCode) {
    this.directionIsUp = fieldType === FilterFieldField.moveSrcLafUp;
    this.cabinCode = cabinCode;
    this.actionType = this.directionIsUp
      ? FlightActionType.moveSingleInventoryManagementSrcLafUp
      : FlightActionType.moveSingleInventoryManagementSrcLafDown;
    this.fieldType = fieldType;
    this.label = this.directionIsUp
      ? t('flight_actions_module.cabin_actions.single_inventory_management_actions.move_scr_laf_action.move_src_laf_up')
      : t('flight_actions_module.cabin_actions.single_inventory_management_actions.move_scr_laf_action.move_src_laf_down');
  }

  public get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + this.fieldType,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: this.actionType,
        field: this.fieldType,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + this.fieldType,
        uniqueField: this.cabinCode,
        componentName: ComponentName.MoveSrcLafField,
        isValueValid: (value: MoveSingleInventoryManagementSrcLAFFieldPayload) =>
          isArrayValueNotEmpty(value.tourOperators) && isValueNotEmpty(value.value),
        value: this.value,
        dataTest: this.directionIsUp ? 'move-src-laf-up-input' : 'move-src-laf-down-input',
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.cabinCode = action.uniqueField as CabinCode;
    this.value = action.value;
  }

  public getPayload(): MoveSingleInventoryManagementSrcLAFPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      tourOperators: this.value.tourOperators,
      value: this.value.value,
    };
  }

  public setPayload(payload: MoveSingleInventoryManagementSrcLAFPayload): void {
    this.cabinCode = payload.cabinCode;
    this.value.tourOperators = payload.tourOperators;
    this.value.value = payload.value;
  }
}
