import axios, { CancelToken } from 'axios';
import { ElMessage } from 'element-plus';

import { ApiClient, EddyBaseError, IntegrationClient } from '@/modules/api/base-client';
import {
  FlightFilterModel,
  FlightLineModel,
  IOndsService,
  NegotiatedAllocations,
  SearchRequestParams,
  SlimFlightLineModel,
  SourceFlight,
} from '@/modules/api/flight/flight-contracts';
import { Page } from '@/modules/api/shared-contracts';
import { CalendarEventModel } from '@/modules/event-management/api/event-management.contracts';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { MessageService } from '@/modules/shared';
import { ErrorCode } from '@/modules/shared/types/error-codes';
import { ITag } from '@/modules/tags';
import { i18n } from '@/plugins/i18n';
import { DateTimeService } from '@/services/date-time.service';
import { FilterService } from '@/services/filter.service';

const { t } = i18n.global;

export class OndsService implements IOndsService {
  public basePath = 'onds';

  public async getDeparted(filters: FlightFilterModel): Promise<FlightLineModel[]> {
    const flight = await ApiClient.get<FlightLineModel[]>(`${this.basePath}/departed`, filters);
    return this.setFlightLegsDepartureDates(flight);
  }

  private setFlightLegsDepartureDates(flight: FlightLineModel[]): FlightLineModel[] {
    if (flight) {
      flight.forEach((fl: FlightLineModel) => {
        if (!fl.legs) {
          return;
        }

        fl.legs = fl.legs.map((leg) => {
          leg.departureDate = DateTimeService.getDate({
            date: leg.departureDate.toString(),
          });

          return leg;
        });
      });
    }

    return flight;
  }

  // for saving tags
  public async patch(id: number, tags: ITag[]): Promise<FlightLineModel> {
    const createdTags = tags.filter((tag: ITag) => tag.id);

    const tagsList = createdTags.map((tag: ITag) => tag.id);

    const request = {
      tags: tagsList,
    };

    return await ApiClient.patch<FlightLineModel>(`/${this.basePath}/${id}`, request);
  }

  public async search(
    transformedRequest: any,
    cancelToken: CancelToken,
    gold = false,
    queryParams?: SearchRequestParams,
  ): Promise<{ page: Page; content: SlimFlightLineModel[] }> {
    try {
      return await ApiClient.post<{
        page: Page;
        content: FlightLineModel[];
      }>(`${this.basePath}${gold ? '/gold' : ''}/search`, transformedRequest, queryParams, cancelToken);
    } catch (error) {
      if (axios.isCancel(error)) {
        ElMessage.info({
          message: t('search_cancelled'),
          duration: 2000,
        });
      }

      throw error;
    }
  }

  // control search pagination
  public async searchAll(
    request: FilterFieldDefinition[],
    cancelToken: CancelToken,
    params = {
      page: 0,
      size: 20000,
    },
    gold = false,
    excludeClasses?: boolean,
  ): Promise<SlimFlightLineModel[]> {
    const transformedRequest = FilterService.transformRequest(request);

    try {
      const search = await this.search(transformedRequest, cancelToken, gold, { size: params.size.toString(), excludeClasses });
      if (!search?.content) {
        throw new Error('RESPONSE_OVERLOAD');
      } else {
        return search.content;
      }
    } catch (error) {
      this.handleSearchError(error as Error | EddyBaseError);
      return [];
    }
  }

  public async getByFlightKeys(
    flightKeys: string[],
    params: { page: number; size: number } = {
      page: 0,
      size: 20000,
    },
    cancelToken: CancelToken,
  ): Promise<SlimFlightLineModel[]> {
    try {
      const response = await ApiClient.post<{ content: SlimFlightLineModel[]; page: Page }>(
        `/onds/gold/search/flight-keys`,
        { flightKeys },
        { size: params.size },
        cancelToken,
      );

      if (!response?.content) {
        throw new Error('RESPONSE_OVERLOAD');
      } else {
        return response.content;
      }
    } catch (error) {
      this.handleSearchError(error as Error | EddyBaseError);

      return [];
    }
  }

  // get single flight / source flight
  public async get(filters: FlightFilterModel, gold = false): Promise<FlightLineModel[]> {
    const flight = await ApiClient.get<FlightLineModel[]>(`${this.basePath}${gold ? '/gold' : ''}`, filters);
    return this.setFlightLegsDepartureDates(flight);
  }

  public async getSourceFlight(filters: FlightFilterModel): Promise<SourceFlight[]> {
    return await ApiClient.get<SourceFlight[]>(`${this.basePath}/source-flights`, filters);
  }

  public async getById(id: number): Promise<FlightLineModel | undefined> {
    const flight = await ApiClient.get<FlightLineModel>(`${this.basePath}/${id}`);

    if (!flight.legs) {
      return;
    }
    flight.legs = flight.legs.map((leg) => {
      leg.departureDate = DateTimeService.getDate({
        date: leg.departureDate.toString(),
      });
      return leg;
    });

    return flight;
  }

  public async getNegotiatedAllocations(ondId: number): Promise<NegotiatedAllocations[]> {
    return await ApiClient.get<NegotiatedAllocations[]>(`${this.basePath}/${ondId}/negotiated-allocations`);
  }

  public async requestRealTimeBookingUpdate(carrierCode: string, flightNumber: string, departureDate: string): Promise<{ status: string }> {
    return await IntegrationClient.post<{ status: string }>('realtime/booked', {
      carrierCode,
      flightNumber,
      departureDate,
    });
  }

  public async updateLastViewed(ondId: number): Promise<string> {
    return await ApiClient.put<string>(`${this.basePath}/${ondId}/view`, '');
  }

  public async getCalendarEventsById(id: number): Promise<CalendarEventModel[]> {
    return await ApiClient.get<CalendarEventModel[]>(`${this.basePath}/${id}/calendar-events`);
  }

  private handleSearchError(error: Error | EddyBaseError): void {
    if ((error as EddyBaseError)?.response) {
      const err = error as EddyBaseError;
      if (err.response.data.errors.find((error) => error.errorCode === ErrorCode.TooManyResults)) {
        MessageService.refineSearch();
      } else if (err.response.data.errors.find((error) => error.errorCode === ErrorCode.NoFlights)) {
        MessageService.noPlannedFlights();
      } else {
        MessageService.failedRequest();
      }
    } else if ((error as Error)?.message === 'RESPONSE_OVERLOAD') {
      MessageService.responseOverload();
    } else {
      MessageService.failedRequest();
    }
  }
}

export const ondsService = new OndsService();
