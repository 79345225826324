import axios from 'axios';

import { Environment } from '@/environment';
import { registerErrorInterceptor } from '@/modules/api/interceptors/error.interceptor';

// Set default config for all api calls
axios.defaults.baseURL = Environment.apiUrl;

export function initApiModule(): void {
  registerErrorInterceptor(axios);
}
