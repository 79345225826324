import { Option } from 'element-plus/es/components/select-v2/src/select.types';
import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { Ref, WritableComputedRef, computed, ref } from 'vue';

import { FlightViewLegCabinInventoryTactic } from '@/models/FlightModel';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { ApiError } from '@/modules/api/base-client';
import { useFlightActionsStore } from '@/modules/flight-actions/store/flight-actions.store';
import { logger } from '@/modules/monitoring';
import { MessageService, handleErrors } from '@/modules/shared';
import {
  AssignableTourOperatorContract,
  FareGrid,
  SingleInventoryManagement,
  TourOperatorContractDto,
  singleInventoryManagementService,
} from '@/modules/tour-operator-details/api/single-inventory-management.service';
import { i18n } from '@/plugins/i18n';

export type SingleInventoryManagementFlightKey = `${FlightViewLegCabinInventoryTactic['flightKey']}`;
export type SingleInventoryManagementFlightKeyCabinCode = `${FlightViewLegCabinInventoryTactic['flightKey']}-${CabinCode}`;
export type SingleInventoryManagementSelectedLegCabinType = {
  flightKey: FlightViewLegCabinInventoryTactic['flightKey'];
  cabinCode: CabinCode;
  maxAdjustedAllocation: number | undefined;
};
const { t, locale } = i18n.global;

export const useSingleInventoryManagementStore = defineStore('singleInventoryManagement', () => {
  const actionsStore = useFlightActionsStore();
  /**
   * Handles actions dispatched to the `actionsStore`.
   *
   * @param {Array} args - The arguments passed to the action. this contains either flightlines or a single flightline
   * When that flightline is present in this store, remove the flightline's data from the store. The action made the data possibly invalid
   */
  actionsStore.$onAction(({ args }) => {
    const modifiedFlightKeys = args
      .flatMap((arg) =>
        'flightLines' in arg
          ? arg.flightLines.map((flightline) => flightline.flightKey)
          : 'flightLine' in arg
            ? [arg.flightLine.flightKey]
            : [],
      )
      .filter(Boolean);

    modifiedFlightKeys.forEach((flightKey) => {
      singleInventoryManagementContracts.value.forEach((_, key) => {
        if (key.startsWith(flightKey)) {
          singleInventoryManagementContracts.value.delete(key);
        }
      });
    });
  });

  const singleInventoryManagementContracts: Ref<Map<SingleInventoryManagementFlightKeyCabinCode, SingleInventoryManagement>> = ref(
    new Map(),
  );
  const singleInventoryManagementFareGrids: Ref<Map<SingleInventoryManagementFlightKey, FareGrid[]>> = ref(new Map());
  const assignableContracts: Ref<AssignableTourOperatorContract[]> = ref([]);
  const assignableContractsWithoutPool: Ref<AssignableTourOperatorContract[]> = computed(() =>
    assignableContracts.value.filter((contract) => !['PL', 'POOL'].includes(contract.contractType)),
  );

  const singleInventoryManagementGridDirty: Ref<boolean> = ref(false);
  const selectedLegCabin: Ref<SingleInventoryManagementSelectedLegCabinType | null> = ref(null);

  const offsetOptions: Ref<Option[]> = ref([
    { label: '3', value: 3 },
    { label: '2', value: 2 },
    { label: '1', value: 1 },
    { label: '0', value: 0 },
    { label: '-1', value: -1 },
    { label: '-2', value: -2 },
    { label: '-3', value: -3 },
  ]);

  const fareGrids: Ref<FareGrid[]> = ref([]);
  const fareClasses: Ref<string[]> = ref([]);

  const collator = new Intl.Collator(locale.value);

  /**
   * Distinct list of available contract types.
   */
  const _tourOperatorContractTypes: Ref<TourOperatorContractDto[]> = ref([]);
  const tourOperatorContractTypes: WritableComputedRef<TourOperatorContractDto[]> = computed<TourOperatorContractDto[]>({
    get: () => _tourOperatorContractTypes.value.sort((a, b) => collator.compare(a.contractType, b.contractType)),
    set: (value: TourOperatorContractDto[]) => (_tourOperatorContractTypes.value = value),
  });

  function showSingleInventoryManagement(flightKey: string, cabinCode: CabinCode, maxAdjustedAllocation: number | undefined): void {
    selectedLegCabin.value = {
      flightKey,
      cabinCode,
      maxAdjustedAllocation,
    };
  }

  function clearSingleInventoryManagementSelection(): void {
    selectedLegCabin.value = null;
  }

  async function getSingleInventoryManagementContracts(
    flightKey: FlightViewLegCabinInventoryTactic['flightKey'],
    cabinCode: CabinCode,
  ): Promise<void> {
    try {
      if (!singleInventoryManagementContracts.value.has(`${flightKey}-${cabinCode}`)) {
        const singleInventoryManagementResponse: SingleInventoryManagement =
          await singleInventoryManagementService.getTourOperatorContracts(flightKey, cabinCode);
        singleInventoryManagementContracts.value.set(`${flightKey}-${cabinCode}`, singleInventoryManagementResponse);
      }
    } catch (e) {
      logger.error(e as Error);
      MessageService.error();
    }
  }

  async function saveSingleInventoryManagementContract(
    singleInventoryManagement: SingleInventoryManagement,
    preview: boolean,
  ): Promise<SingleInventoryManagement> {
    const { flightKey, cabinCode } = selectedLegCabin.value ?? {};
    if (!flightKey || !cabinCode) {
      throw new Error('Flight key and cabin code must be selected');
    }

    try {
      const result = await singleInventoryManagementService.saveTourOperatorContract(
        flightKey,
        cabinCode,
        singleInventoryManagement,
        preview,
      );
      if (!preview) {
        singleInventoryManagementContracts.value.set(`${flightKey}-${cabinCode}`, cloneDeep(result));
        singleInventoryManagementGridDirty.value = false;
      }
      return result;
    } catch (e) {
      handleErrors(e as ApiError);
      throw new Error('Failed to save/preview Single Inventory Management Contract');
    }
  }

  async function getFareGridsForFlight(flightKey: FlightViewLegCabinInventoryTactic['flightKey']): Promise<void> {
    try {
      if (!singleInventoryManagementFareGrids.value.has(`${flightKey}`)) {
        const fareGridsResponse = (await singleInventoryManagementService.getFareGridsForFlight(flightKey)).sort((a, b) =>
          a.fareGridCode.localeCompare(b.fareGridCode),
        );
        singleInventoryManagementFareGrids.value.set(`${flightKey}`, fareGridsResponse);
      }
    } catch (e) {
      logger.error(e as Error);
      MessageService.error();
    }
  }

  async function getFareGrids(): Promise<void> {
    try {
      if (!fareGrids.value.length) {
        fareGrids.value = (await singleInventoryManagementService.getFareGrids()).sort((a, b) =>
          a.fareGridCode.localeCompare(b.fareGridCode),
        );
      }
    } catch (e) {
      logger.error(e as Error);
      MessageService.error();
    }
  }

  async function getFareClasses(): Promise<void> {
    try {
      if (!fareClasses.value.length) {
        fareClasses.value = await singleInventoryManagementService.getFareClasses();
      }
    } catch (e) {
      logger.error(e as Error);
      MessageService.error();
    }
  }

  async function getTourOperatorContractTypes(): Promise<void> {
    try {
      if (!tourOperatorContractTypes.value.length) {
        tourOperatorContractTypes.value = await singleInventoryManagementService.getTourOperatorContractTypes();
      }
    } catch (e) {
      logger.error(e as Error);
      MessageService.error(t('tour_operator_messages.failed_to_get_contract_types'));
    }
  }

  async function getAssignableTourOperatorContracts(): Promise<void> {
    try {
      if (!assignableContracts.value.length) {
        assignableContracts.value = (await singleInventoryManagementService.getAvailableTourOperators()).sort(
          (a, b) => a.contractType.localeCompare(b.contractType) || a.contractCode.localeCompare(b.contractCode),
        );
      }
    } catch (e) {
      logger.error(e as Error);
      MessageService.error();
    }
  }

  function $reset(): void {
    singleInventoryManagementContracts.value = new Map();
  }

  return {
    fareGrids,
    fareClasses,
    selectedLegCabin,
    offsetOptions,
    assignableContracts,
    assignableContractsWithoutPool,
    singleInventoryManagementContracts,
    singleInventoryManagementFareGrids,
    singleInventoryManagementGridDirty,
    tourOperatorContractTypes,
    clearSingleInventoryManagementSelection,
    getFareGridsForFlight,
    getFareGrids,
    getFareClasses,
    getSingleInventoryManagementContracts,
    saveSingleInventoryManagementContract,
    showSingleInventoryManagement,
    getTourOperatorContractTypes,
    getAssignableTourOperatorContracts,
    $reset,
  };
});
