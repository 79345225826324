export const en = {
  audit_log: {
    flight_audit: {
      sources: {
        user: 'User Action',
        automation: 'FlowFx',
        async_update: 'Async Update',
        unkown: 'Unknown',
      },
      download_changes_as_csv: 'Download Changes as CSV',
      lowest_available_fare: 'Lowest Available Fare',
      class_change_history: 'Class Change History For',
      no_class_changes: 'There were no changes to the Classes on this Flight.',
      error_loading_class_changes:
        'There was an error loading the changes. Please try again. If the error persists, please contact support.',
      showing_protections: 'Showing Protection changes',
      showing_authorization_units: 'Showing Authorization Unit changes',
      user: 'User',
      all: 'All',
      eddy: 'SRM Flex',
      details: 'Details',
      show_changes_made_by: 'Show Changes Made By',
    },
    audit_change_model: {
      tour_operator_adjustments: 'Tour Operator Adjustments',
      added_tour_operators: 'Added Tour Operators',
      tour_operator_changes: 'Tour Operator Changes',
      cabin: 'Cabin',
      tour_operator: 'Tour Operator',
      contract_type: 'Contract Type',
      tour_operator_fields: {
        calculated_class: 'Calculated Class',
        src_laf: 'srcLAF',
        fare: 'Fare',
        adjusted_allocation: 'Adjusted Allocation',
        fare_grid: 'Fare Grid',
        offset: 'Offset',
        ceiling: 'Ceiling',
        before: 'Before',
        after: 'After',
        active: 'Active',
        adjusted_single_inventory_management_pool_allocation: 'Pool allocation',
      },
    },
  },
};
