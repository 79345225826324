import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { IOptimizationProfile, IOptimizationProfiles } from '@/modules/control/api/optimisation-profiles.contracts';
import { optimizationProfileService } from '@/modules/control/api/optimisation-profiles.service';
import { logger } from '@/modules/monitoring';

export const useOptimisationProfilesStore = defineStore('optimisationProfiles', () => {
  const allOptimisationProfilesDefaultValue: IOptimizationProfiles = { profiles: [], byor: [], dsOp: [] };
  const allOptimisationProfiles: Ref<IOptimizationProfiles> = ref({ ...allOptimisationProfilesDefaultValue });
  const combinedProfiles: ComputedRef<IOptimizationProfile[]> = computed(() => [].concat(...Object.values(allOptimisationProfiles.value)));

  const optimisationProfilesByFlight: Ref<Map<string, IOptimizationProfiles>> = ref(new Map());

  function getSortedOptimisationProfiles(profiles: IOptimizationProfiles): IOptimizationProfiles {
    return Object.keys(profiles).reduce(
      (acc, profileType): IOptimizationProfiles => {
        const type = profileType as keyof IOptimizationProfiles;

        acc[type] = profiles[type].map(({ id, name, description, active, levels, type }) => ({
          id,
          name,
          description,
          active,
          levels: levels.sort((a, b) => b.level - a.level),
          type,
        }));

        return acc;
      },
      { ...allOptimisationProfilesDefaultValue },
    );
  }

  async function getOptimisationProfileForFlight(flightKey: string): Promise<IOptimizationProfiles | undefined> {
    if (optimisationProfilesByFlight.value.has(flightKey)) {
      return optimisationProfilesByFlight.value.get(flightKey);
    }

    try {
      const response = await optimizationProfileService.getForFlight(flightKey);
      const sortedResponse = getSortedOptimisationProfiles(response);
      optimisationProfilesByFlight.value.set(flightKey, sortedResponse);
      return sortedResponse;
    } catch (e) {
      logger.error(e as Error);
    }
  }

  async function getAll(): Promise<void> {
    try {
      const response = await optimizationProfileService.getAll();
      allOptimisationProfiles.value = getSortedOptimisationProfiles(response);
    } catch (e) {
      logger.error(e as Error);
    }
  }

  function $reset(): void {
    allOptimisationProfiles.value = { ...allOptimisationProfilesDefaultValue };
  }

  return {
    allOptimisationProfiles,
    combinedProfiles,
    getOptimisationProfileForFlight,
    getAll,
    $reset,
  };
});
