import { CabinService } from '@/modules/shared/services/cabin.service';
import { OptionGroup } from '@/modules/shared/types/select.interface';
import { useAppSettingsStore } from '@/store/modules/app-settings.store';

import { LinkedClassRuleDetailModel } from '../models/linked-class-rule.model';

export function getFilteredClassCodeOptions(classToExclude: string): OptionGroup[] {
  const appSettingsStore = useAppSettingsStore();

  return appSettingsStore.inventoryConfigurationProperties.cabins.map(
    (cabin) =>
      ({
        label: cabin.name,

        options: CabinService.getCabinClasses(
          appSettingsStore.inventoryConfigurationProperties.pss,
          appSettingsStore.inventoryConfigurationProperties.cabins,
          cabin.code,
        )
          .map((cls) => ({
            value: cls.code,
            label: cls.code,
          }))
          .filter((option) => option.value !== classToExclude),
      }) as OptionGroup,
  );
}

/**
 * Filters the class code options, by exluding the linking class selected (you cannot select the same class as class _and_ linking class),
 * and other classes that are already linked in this model.
 */
export function getClassCodeOptionsExcludingRule(rules: LinkedClassRuleDetailModel[], classToExclude: string): OptionGroup[] {
  const appSettingsStore = useAppSettingsStore();

  return appSettingsStore.cabinClassesWithOutTopClasses.map(
    (cabin) =>
      ({
        label: cabin.name,

        options: CabinService.getCabinClassesWithoutTopClass(
          appSettingsStore.inventoryConfigurationProperties.pss,
          appSettingsStore.inventoryConfigurationProperties.cabins,
          cabin.code,
        )
          .map((cls) => ({
            value: cls.code,
            label: cls.code,
          }))
          .filter((option) => option.value !== classToExclude && !rules.some((rule) => rule.classCode === option.value)),
      }) as OptionGroup,
  );
}
