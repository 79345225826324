import { defineStore, storeToRefs } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { FlightViewLegCabinInfo } from '@/models/FlightModel';
import { CabinAgreement, FlightLegCabin } from '@/modules/api/flight/flight-contracts';
import { FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { useAppSettingsStore } from '@/store/modules/app-settings.store';

export const useOverbookingStore = defineStore('overbooking', () => {
  const selectedLegCabin: Ref<FlightViewLegCabinInfo | undefined> = ref();
  const cabins: Ref<FlightViewLegCabinInfo[]> = ref([]);
  const balancedAdjustment: Ref<number> = ref(0);
  const defaultUnbalancedAdjustment: Ref<number> = ref(0);
  const unbalancedAdjustment: Ref<number> = ref(defaultUnbalancedAdjustment.value);
  const authorizedCapacity: Ref<number> = ref(0);
  const defaultOverbookingFactor: Ref<number> = ref(100);
  const overbookingFactor: Ref<number> = ref(defaultOverbookingFactor.value);
  const recommendedOverbookingFactor: Ref<number> = ref(defaultOverbookingFactor.value);
  const overbookingRisk: Ref<number | undefined> = ref();
  const overbookingAutopilot: Ref<boolean> = ref(false);
  const appSettingsStore = useAppSettingsStore();
  const { inventoryConfigurationProperties } = storeToRefs(appSettingsStore);

  const cabinOrder: ComputedRef<number | undefined> = computed(() => {
    if (!selectedLegCabin.value) return;

    return inventoryConfigurationProperties.value?.cabins?.find((cabin) => cabin.code === selectedLegCabin.value?.cabinCode)?.order;
  });

  const totalAdjustments: ComputedRef<number> = computed(() => {
    const adjustment = cabinOrder.value === 0 ? -1 * balancedAdjustment.value : balancedAdjustment.value;
    const agreements = selectedLegCabin.value?.cabinAgreements ?? [];

    return (
      unbalancedAdjustment.value + adjustment - agreements.reduce((accAdjustment, agreement) => accAdjustment + (agreement.blocked || 0), 0)
    );
  });

  /**
   * Saleable capacity + balance adjustments
   */
  const saleableCapacity: ComputedRef<number> = computed(() => selectedLegCabin.value?.saleableCapacity ?? 0);
  const allotment: ComputedRef<number> = computed(() => selectedLegCabin.value?.allotment ?? 0);
  const totalCapacity: ComputedRef<number> = computed(() => saleableCapacity.value + totalAdjustments.value - allotment.value);

  const overbookedCapacity: ComputedRef<number> = computed(() => Math.round(totalCapacity.value * (overbookingFactor.value / 100)));

  const recommendedAbsoluteOverbooking: ComputedRef<number> = computed(() =>
    Math.round(recommendedOverbookedCapacity.value - totalCapacity.value),
  );

  const recommendedOverbookedCapacity: ComputedRef<number> = computed(() =>
    Math.round(totalCapacity.value * (recommendedOverbookingFactor.value / 100)),
  );

  const absoluteOverbooking: ComputedRef<number> = computed(() => Math.round(overbookedCapacity.value - totalCapacity.value));

  const leftoverCapacity: ComputedRef<number | undefined> = computed(() => selectedLegCabin.value?.updatedLeftoverCapacity);

  const cabinAgreementSummary: ComputedRef<string> = computed(() => {
    const cabinAgreements: CabinAgreement[] | undefined = selectedLegCabin.value?.cabinAgreements;

    if (!cabinAgreements) return '-';

    const blockedSum = cabinAgreements.reduce((a, b) => a + (b.blocked || 0), 0);
    const bookedSum = cabinAgreements.reduce((a, b) => a + (b.booked || 0), 0);

    return `${bookedSum} / ${blockedSum}`;
  });

  function init(payload: { selectedLegCabin: FlightViewLegCabinInfo; cabins: FlightViewLegCabinInfo[] }): void {
    selectedLegCabin.value = payload.selectedLegCabin;
    cabins.value = payload.cabins;

    setUpdatedLeftOverCapacity(selectedLegCabin.value?.leftoverCapacity);

    // TODO: Potentially use derived values
    overbookingFactor.value = selectedLegCabin.value.overbookingFactor;
    unbalancedAdjustment.value = selectedLegCabin.value.unbalancedAdjustment ?? 0;
    balancedAdjustment.value =
      cabins.value?.length && cabinOrder.value === 0
        ? (cabins.value[0].balancedAdjustment ?? 0)
        : (selectedLegCabin.value.balancedAdjustment ?? 0);
  }

  function setUpdatedLeftOverCapacity(capacity: number): void {
    if (selectedLegCabin.value) {
      selectedLegCabin.value.updatedLeftoverCapacity = capacity;
    }
  }

  function applyAdjustments(): void {
    setLegCabinBalancedAdjustments();
    setLegCabinUnbalancedAdjustment();
  }

  function setLegCabinBalancedAdjustments(): void {
    if (selectedLegCabin.value) {
      selectedLegCabin.value.balancedAdjustment = cabinOrder.value === 0 ? balancedAdjustment.value * -1 : balancedAdjustment.value;
    }

    if (cabins.value.length) {
      // Assumes we only have one other cabin
      cabins.value[0].balancedAdjustment = cabinOrder.value === 0 ? balancedAdjustment.value : balancedAdjustment.value * -1;
    }
  }

  function setLegCabinUnbalancedAdjustment(): void {
    if (selectedLegCabin.value) {
      selectedLegCabin.value.unbalancedAdjustment = unbalancedAdjustment.value;
    }
  }

  function resolveLeftover(payload: { action: FlightActionType; updatedCabin: FlightLegCabin }): void {
    switch (payload.action) {
      case FlightActionType.resolveByMatchingOvb:
        overbookingFactor.value = payload.updatedCabin.overbookingFactor;
        setUpdatedLeftOverCapacity(payload.updatedCabin.leftoverCapacity);
        break;
      case FlightActionType.resolveByMatchingUnbalancedAdjustment:
        unbalancedAdjustment.value = payload.updatedCabin.updatedUnbalancedAdjustment ?? 0;
        setUpdatedLeftOverCapacity(payload.updatedCabin.leftoverCapacity);
        break;
      case FlightActionType.resolveByIgnoring:
        authorizedCapacity.value = payload.updatedCabin.updatedAuthorizedCapacity ?? 0;
        setUpdatedLeftOverCapacity(0);
        break;
      default:
        break;
    }
  }

  function applyOverbookingFactor(): void {
    if (selectedLegCabin.value) {
      selectedLegCabin.value.overbookingFactor = overbookingFactor.value;
    }
  }

  function $reset(): void {
    selectedLegCabin.value = undefined;
    cabins.value = [];
    balancedAdjustment.value = 0;
    defaultUnbalancedAdjustment.value = 0;
    unbalancedAdjustment.value = 0;
    authorizedCapacity.value = 0;
    defaultOverbookingFactor.value = 100;
    overbookingFactor.value = defaultOverbookingFactor.value;
    recommendedOverbookingFactor.value = defaultOverbookingFactor.value;
    overbookingRisk.value = undefined;
    overbookingAutopilot.value = false;
  }

  return {
    absoluteOverbooking,
    authorizedCapacity, // TODO: Seems to only be used in the test, so let's find another way to evaluate this value/test this
    defaultOverbookingFactor,
    defaultUnbalancedAdjustment,
    leftoverCapacity,
    overbookedCapacity,
    overbookingAutopilot,
    overbookingFactor,
    overbookingRisk,
    recommendedAbsoluteOverbooking,
    recommendedOverbookedCapacity,
    recommendedOverbookingFactor,
    selectedLegCabin,
    saleableCapacity,
    totalCapacity,
    cabinOrder,
    cabinAgreementSummary,
    totalAdjustments,
    balancedAdjustment,
    unbalancedAdjustment,
    allotment,
    cabins, // TODO: Seems to only be used in the test, so let's find another way to evaluate this value/test this
    init,
    resolveLeftover,
    applyAdjustments,
    applyOverbookingFactor,
    setLegCabinUnbalancedAdjustment,
    $reset,
  };
});
