import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { isArrayValueNotEmpty, isValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { AssignableTourOperatorContract } from '@/modules/tour-operator-details/api/single-inventory-management.service';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface ToggleSingleInventoryManagementContractStatusPayload
  extends ToggleSingleInventoryManagementContractStatusFieldPayload,
    BaseFlightActionPayload {
  cabinCode: CabinCode | undefined;
}

export interface ToggleSingleInventoryManagementContractStatusFieldPayload {
  active: boolean | undefined;
  tourOperators: AssignableTourOperatorContract[];
}

export class ToggleSingleInventoryManagementContractStatusAction
  implements FlightAction<ToggleSingleInventoryManagementContractStatusPayload>
{
  public actionType: FlightActionType = FlightActionType.toggleSingleInventoryManagementContractStatus;
  public label = t(
    'flight_actions_module.cabin_actions.single_inventory_management_actions.toggle_contract_status_action.toggle_contract_status',
  );
  public value: ToggleSingleInventoryManagementContractStatusFieldPayload = {
    tourOperators: [],
    active: false,
  };
  public cabinCode: CabinCode | undefined;

  constructor(cabinCode?: CabinCode) {
    this.cabinCode = cabinCode;
  }

  public get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.toggleContractStatus,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.toggleSingleInventoryManagementContractStatus,
        field: FilterFieldField.toggleContractStatus,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.toggleContractStatus,
        uniqueField: this.cabinCode,
        componentName: ComponentName.ToggleContractStatusField,
        isValueValid: (value: ToggleSingleInventoryManagementContractStatusFieldPayload) =>
          isArrayValueNotEmpty(value.tourOperators) && isValueNotEmpty(value.active),
        value: this.value,
        dataTest: 'toggle-contract-status-input',
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.cabinCode = action.uniqueField as CabinCode;
    this.value = action.value;
  }

  public getPayload(): ToggleSingleInventoryManagementContractStatusPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      tourOperators: this.value.tourOperators,
      active: this.value.active,
    };
  }

  public setPayload(payload: ToggleSingleInventoryManagementContractStatusPayload): void {
    this.cabinCode = payload.cabinCode;
    this.value.tourOperators = payload.tourOperators;
    this.value.active = payload.active;
  }
}
