import { ApiError } from '@/modules/api/base-client';
import { logger } from '@/modules/monitoring';
import { MessageService } from '@/modules/shared';
import { ErrorCode, ErrorCodeMessages } from '@/modules/shared/types/error-codes';
import { EddyError } from '@/modules/shared/types/error-response';

export const handleErrors = (apiError?: ApiError, log = true): void => {
  if (log) logger.error(apiError as unknown as Error);

  if (apiError) {
    try {
      const errors = apiError.response.data?.errors as EddyError[];
      MessageService.error(errors[0].uiMessage);
    } catch {
      showDefaultError();
    }
  } else {
    showDefaultError();
  }
};

function showDefaultError(): void {
  MessageService.error(ErrorCodeMessages.get(ErrorCode.DefaultError));
}
