import { storeToRefs } from 'pinia';
import { unref } from 'vue';
import { NavigationGuard } from 'vue-router';

import { Feature } from '@/modules/features/api/features/features.contracts';
import { useFeaturesStore } from '@/modules/features/store/features.store';
import { logger } from '@/modules/monitoring';
import { MessageService } from '@/modules/shared';

export const featureGuard: NavigationGuard = async (to, from, next) => {
  if (to.meta && to.meta.features) {
    const featuresStore = useFeaturesStore();
    const { features } = storeToRefs(featuresStore);
    const requiredFeatures: Feature[] = to.meta.features;

    try {
      const hasAccess: boolean = requiredFeatures.every((requiredFeature) => features.value[requiredFeature] === true);
      if (hasAccess) {
        next();
      } else {
        logger.error(new Error('User tried accessing disabled feature(s)'), {
          features: unref(features),
          requiredFeatures,
        });
        MessageService.noPermission(false);
        next(false);
      }
    } catch (error) {
      logger.error(new Error('User tried accessing disabled feature(s)'), {
        features: unref(features),
        requiredFeatures,
      });
      MessageService.noPermission(false);
      next(false);
    }
  } else {
    next();
  }
};
