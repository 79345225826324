import { CabinStructure, ClassStructure, PssName } from '@/modules/api/application/application-contracts';

export class CabinService {
  /**
   *
   * @param pssName
   * @param cabins
   * @param cabinCode
   * @returns cabin classes filtered on subClass
   */
  public static getCabinClasses(pssName: PssName, cabins: CabinStructure[], cabinCode: string): ClassStructure[] {
    const cabin = cabins.find((cabin) => cabin.code === cabinCode);

    switch (pssName) {
      case PssName.Amadeus:
      case PssName.Radixx:
      case PssName.Sabre:
        return cabin ? cabin.classes : [];
      case PssName.Navitaire:
      case PssName.Genius_TurSys:
      case PssName.Pribas:
      case PssName.Videcom:
        return cabin ? cabin.classes.filter((cabinClass) => !cabinClass.subClass) : [];
      case PssName.Ibs:
        return cabin ? cabin.classes.filter((cabinClass) => !cabinClass.subClass) : [];
    }
  }

  /**
   *
   * @param pssName
   * @param cabins
   * @param cabinCode
   * @returns cabin classes filtered on subClass and filter out top / cabin class
   */
  public static getCabinClassesWithoutTopClass(pssName: PssName, cabins: CabinStructure[], cabinCode: string): ClassStructure[] {
    return this.getCabinClasses(pssName, cabins, cabinCode).filter((cabinClass) => cabinClass.code !== cabinCode);
  }

  static getCabinLafClasses({ cabinClasses }: { cabinClasses: ClassStructure[] }) {
    return cabinClasses
      .filter((value) => !value.discrete && !value.excludeInLAF && !value.subClass)
      .sort(({ order: a }, { order: b }) => a - b);
  }
}
