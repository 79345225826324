import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { isArrayValueNotEmpty, isValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { AssignableTourOperatorContract } from '@/modules/tour-operator-details/api/single-inventory-management.service';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface SetSingleInventoryManagementAllocationPayload
  extends SetSingleInventoryManagementAllocationFieldPayload,
    BaseFlightActionPayload {
  cabinCode: CabinCode | undefined;
}

export interface SetSingleInventoryManagementAllocationFieldPayload {
  allocation: number | undefined;
  tourOperators: AssignableTourOperatorContract[];
}

export class SetSingleInventoryManagementAllocationAction implements FlightAction<SetSingleInventoryManagementAllocationPayload> {
  public actionType: FlightActionType = FlightActionType.setSingleInventoryManagementAllocation;
  public label = t('flight_actions_module.cabin_actions.single_inventory_management_actions.set_allocation_action.set_allocation');
  public value: SetSingleInventoryManagementAllocationFieldPayload = {
    tourOperators: [],
    allocation: undefined,
  };
  public cabinCode: CabinCode | undefined;

  constructor(cabinCode?: CabinCode) {
    this.cabinCode = cabinCode;
  }

  public get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.setAllocation,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.setSingleInventoryManagementAllocation,
        field: FilterFieldField.setAllocation,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.setAllocation,
        uniqueField: this.cabinCode,
        componentName: ComponentName.SetAllocationField,
        isValueValid: (value: SetSingleInventoryManagementAllocationFieldPayload) =>
          isArrayValueNotEmpty(value.tourOperators) && isValueNotEmpty(value.allocation),
        value: this.value,
        dataTest: 'set-allocation-input',
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.cabinCode = action.uniqueField as CabinCode;
    this.value = action.value;
  }

  public getPayload(): SetSingleInventoryManagementAllocationPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      tourOperators: this.value.tourOperators,
      allocation: this.value.allocation,
    };
  }

  public setPayload(payload: SetSingleInventoryManagementAllocationPayload): void {
    this.cabinCode = payload.cabinCode;
    this.value.tourOperators = payload.tourOperators;
    this.value.allocation = payload.allocation;
  }
}
