import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { isArrayValueNotEmpty, isValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { AssignableTourOperatorContract } from '@/modules/tour-operator-details/api/single-inventory-management.service';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface SetSingleInventoryManagementSrcLAFPayload extends SetSingleInventoryManagementSrcLAFFieldPayload, BaseFlightActionPayload {
  cabinCode: CabinCode | undefined;
}

export interface SetSingleInventoryManagementSrcLAFFieldPayload {
  tourOperators: AssignableTourOperatorContract[];
  srcLAF: string | undefined;
  /**
   * Only applicable for DNC
   */
  resetOffset?: boolean;
}

export class SetSingleInventoryManagementSrcLAFAction implements FlightAction<SetSingleInventoryManagementSrcLAFPayload> {
  public actionType: FlightActionType = FlightActionType.setSingleInventoryManagementSrcLaf;
  public label = t('flight_actions_module.cabin_actions.single_inventory_management_actions.set_src_laf_action.set_src_laf');
  public value: SetSingleInventoryManagementSrcLAFFieldPayload = {
    tourOperators: [],
    srcLAF: 'DNC',
    resetOffset: false,
  };
  public cabinCode: CabinCode | undefined;

  constructor(cabinCode?: CabinCode) {
    this.cabinCode = cabinCode;
  }

  public get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.setSrcLaf,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.setSingleInventoryManagementSrcLaf,
        field: FilterFieldField.setSrcLaf,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.setSrcLaf,
        uniqueField: this.cabinCode,
        componentName: ComponentName.SetSrcLafField,
        isValueValid: (value: SetSingleInventoryManagementSrcLAFFieldPayload) =>
          isArrayValueNotEmpty(value.tourOperators) && isValueNotEmpty(value.srcLAF),
        value: this.value,
        dataTest: 'set-src-laf-input',
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.cabinCode = action.uniqueField as CabinCode;
    this.value = action.value;
  }

  public getPayload(): SetSingleInventoryManagementSrcLAFPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      srcLAF: this.value.srcLAF,
      tourOperators: this.value.tourOperators,
      resetOffset: this.value.resetOffset,
    };
  }

  public setPayload(payload: SetSingleInventoryManagementSrcLAFPayload): void {
    this.cabinCode = payload.cabinCode;
    this.value.tourOperators = payload.tourOperators;
    this.value.srcLAF = payload.srcLAF;
    this.value.resetOffset = payload.srcLAF !== 'DNC' ? false : payload.resetOffset;
  }
}
