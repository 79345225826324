export const en = {
  route_management: {
    titles: {
      route_management: 'Route Management',
      route_group_add: 'Add Group for {n} Route | Add Group for {n} Routes',
      route_group_remove: 'Remove Group for {n} Route | Remove Group for {n} Routes',
      set_fare_time_window_for_n_routes: 'Set Fare Time Window for {n} Route | Set Fare Time Window for {n} Routes',
      adjust_currency: 'Adjust Currency for {n} Route | Adjust Currency for {n} Routes ',
      adjust_direction: 'Adjust Direction for {n} Route | Adjust Direction for {n} Routes ',
      continuous_pricing_manager: 'Continuous Pricing Manager for {n} Route | Continuous Pricing Manager for {n} Routes',
      setting_cluster_bulk_assignment: 'Set Clusters for {n} Route | Set Clusters for {n} Routes',
      add_assignments_for_n_routes: 'Add Assignments for {n} Route | Add Assignments for {n} Routes',
      change_assignments_for_n_routes:
        'Change the Assignee. User {u} is Assigned to {n} Route. Please Select New User(s) to Transfer the Route To | Change the Assignee. User {u} is Assigned to {n} Routes. Please Select New User(s) to Transfer the Routes To',
      remove_assignments_for_n_routes: 'Remove Assignments for {n} Route | Remove Assignments for {n} Routes',
      review_queue: 'Review Queue',
      adjustment_increment_bulk_assignment: 'Set Adjustment Increment for {n} route | Set Adjustment Increment for {n} routes',
      forecast_and_optimization: 'Forecast & Optimization',
      enable_forecast_generation: 'Enable Forecast Generation',
      disable_forecast_generation: 'Disable Forecast Generation',
      enable_forecast_learning: 'Enable Forecast Learning',
      disable_forecast_learning: 'Disable Forecast Learning',
    },
    state: {
      loading_routes: 'Loading Routes',
      removing_assignments: 'Removing Assignments',
      adding_assignments: 'Adding Assignments',
      setting_competitor_fare_time_window: 'Setting Competitor Fare Time Window',
      saved_n_routes: 'Saved {n} Route | Saved {n} Routes',
      loading_currency_change: 'Loading Currency Change',
      changing_direction: 'Changing Direction',
      set_continuous_pricing_window: 'Setting Continuous Pricing Manager Window',
      all_routes_updated: 'All Routes Have Been Updated',
      changes_applied: 'Your Changes Have Been Applied',
      setting_cluster_bulk_assignment_window: 'Setting Cluster Bulk Assignment Window',
      no_cluster_assignments: 'No cluster assignments present for this cabin.',
      saved_routes: 'Saved {n} Route | Saved {n} Routes',
      setting_adjustment_increment_bulk_assignment_window: 'Setting adjustment increment bulk assign window...',
    },
    labels: {
      use_large_cf_window: 'Widen CF Range',
      edit_routes: 'Edit Routes',
      select_groups: 'Select Groups',
      select_time_window: 'Select Time Window',
      selected_time_window: 'Selected Time Window',
      select_currency: 'Select Currency',
      select_direction: 'Select Direction',
      carrier_code_short: 'Carrier',
      route: 'Route',
      season: 'Season',
      filter_on: 'Filter On',
      no_route_price_range_set: 'No Minimum Price Set',
      class_code: 'Class Code',
      minimum_price: 'Minimum Price',
      copy_assignments_from: 'Copy Assignments From',
      route_key: 'Route Key',
      pacing_curve: 'Pacing Curve',
      cluster: 'Cluster',
      cabin: '{cabinCode} Cabin',
      hours_short: '{n}h',
      cabin_code: 'Cabin Code',
      adjustment_increment_percentage: 'Adjustment increment %',
      byor_adjustment_increment: 'BYOR Adjustment Increment',
      byor_set_adjustment_increment: 'Set Adjustment Increment % (BYOR)',
      forecast_dynamic_program_adjustment_increment: 'Dynamic Program Adjustment Increment',
      forecast_dynamic_program_set_adjustment_increment: 'Set Adjustment Increment % (Dynamic Program)',
      byor_op_level_adjustment_increment: '% Increment (BYOR)',
      byor_op_level_adjustment_increment_tooltip: 'OP Level Adjustment Increment for BYOR',
      forecast_dynamic_program_op_level_adjustment_increment: '% Increment (Dynamic Program)',
      forecast_dynamic_program_op_level_adjustment_increment_tooltip: 'OP Level Adjustment Increment for Dynamic Program',
    },
    info: {
      n_n_selected: '{0} / {1} Selected',
      saved_n_routes: 'Saved {n} route | Saved {n} routes',
      to: 'to',
      of: 'of',
      confirm_continuous_pricing_all_selected_routes: 'Are you sure you want to apply the Route Price Range to all {n} selected routes?',
      cluster_bulk_assignment_warning:
        'The selected routes do not have the same Cluster assignments. By saving the changes you will overwrite all assignments with the below configuration.',
      cluster_assignment_description:
        'For each cabin, assign the clusters that should be set for the selected routes. Then, assign a pacing curve to each cluster. Clusters are evaluated in numerical order. To change the order of a cluster, click and drag it vertically.',
      disable_forecasting_generation_warning:
        'Are you sure you want to disable Forecasting for {n} Route? | Are you sure you want to disable Forecasting for {n} Routes?',
      disable_forecasting_generation_description:
        "All flights on this route with a selected forecasting tactic for the Active and Shadow Tactic will be set to 'Manual' and Autopilot will be turned off. | All flights" +
        " on these routes with a selected forecasting tactic for the Active and Shadow Tactic will be set to 'Manual' and Autopilot will be turned off.",
    },
    actions: {
      apply_changes: 'Apply Changes',
      apply_price_range_to_all_routes: 'Applying the Route Price Range to All Selected Routes',
      apply_increment_adjustment_to_all_routes: 'Applying the Route Adjustment Increment to All Selected Routes',
      cancel: 'Cancel',
      clear_selection: 'Clear Selection',
      proceed: 'Proceed',
      save: 'Save',
      save_and_next: 'Save & Next',
      save_to_all: 'Save to All',
      clear_all_filters: 'Clear All Filters',
      save_assignments: 'Save Assignments',
    },
    errors: {
      route_price_range_warning: 'Price range must be between prices of class above and below',
      route_price_range_empty_warning: "This route doesn't have a price range yet, please fill in a valid structure.",
      cluster_bulk_assignment_warning: 'Routes have different cluster assignments',
    },
  },
};
