import { InventoryConfigurationProperties } from '@/modules/api/application/application-contracts';
import { SlimFlightLineModel } from '@/modules/api/flight/flight-contracts';
import { FilterFieldField } from '@/modules/api/shared-contracts';
import { AddLinkedClassRuleAction } from '@/modules/flight-actions/actions/add-linked-class-rule-action';
import { AddRivalRuleAction } from '@/modules/flight-actions/actions/add-rival-rule-action';
import { AddOptimizationProfileLevelAction } from '@/modules/flight-actions/actions/cabin-actions/add-optimization-profile-level-action';
import { AddShadowOptimizationProfileLevelAction } from '@/modules/flight-actions/actions/cabin-actions/add-shadow-optimization-profile-level-action';
import { ApplyDatascienceRecommendedLafAction } from '@/modules/flight-actions/actions/cabin-actions/apply-datascience-recommended-laf-action';
import { ApplyRecommendedAUAction } from '@/modules/flight-actions/actions/cabin-actions/apply-recommended-au-action';
import { ApplyRecommendedOverbookingFactorAction } from '@/modules/flight-actions/actions/cabin-actions/apply-recommended-overbooking-factor-action';
import { ChangeBalancedAdjustmentAction } from '@/modules/flight-actions/actions/cabin-actions/change-balanced-adjustment-action';
import { ChangeMultiLegBalancedAdjustmentAction } from '@/modules/flight-actions/actions/cabin-actions/change-multi-leg-balanced-adjustment-action';
import { ChangeMultiLegOverbookingFactorAction } from '@/modules/flight-actions/actions/cabin-actions/change-multi-leg-overbooking-factor-action';
import { ChangeMultiLegUnbalancedAdjustmentAction } from '@/modules/flight-actions/actions/cabin-actions/change-multi-leg-unbalanced-adjustment-action';
import { ChangeSingleLegOverbookingFactorAction } from '@/modules/flight-actions/actions/cabin-actions/change-single-leg-overbooking-factor-action';
import { ChangeUnbalancedAdjustmentAction } from '@/modules/flight-actions/actions/cabin-actions/change-unbalanced-adjustment-action';
import { CorrectAuStructureAction } from '@/modules/flight-actions/actions/cabin-actions/correct-au-structure-action';
import { IncreaseSeatAvailabilityLowestAvailableFareAction } from '@/modules/flight-actions/actions/cabin-actions/increase-seat-availability-lowest-available-fare-action';
import { MatchCompetitorAction } from '@/modules/flight-actions/actions/cabin-actions/match-competitor-action';
import { MoveClusterLevelAction } from '@/modules/flight-actions/actions/cabin-actions/move-cluster-level-action';
import { MoveLowestAvailableFareDownAction } from '@/modules/flight-actions/actions/cabin-actions/move-lowest-available-fare-down-action';
import { MoveLowestAvailableFareUpAction } from '@/modules/flight-actions/actions/cabin-actions/move-lowest-available-fare-up-action';
import { MoveOptimizationProfileLevelAction } from '@/modules/flight-actions/actions/cabin-actions/move-optimization-profile-level-action';
import { MoveRecommendedOverbookingRiskPercentageAction } from '@/modules/flight-actions/actions/cabin-actions/move-recommended-overbooking-risk-percentage-action';
import { MoveShadowOptimizationProfileLevelAction } from '@/modules/flight-actions/actions/cabin-actions/move-shadow-optimization-profile-level-action';
import { MoveTacticalAdjustmentLevelAction } from '@/modules/flight-actions/actions/cabin-actions/move-tactical-adjustment-level-action';
import { RemoveClusterAction } from '@/modules/flight-actions/actions/cabin-actions/remove-cluster-action';
import { RemovePromotionAction } from '@/modules/flight-actions/actions/cabin-actions/remove-promotion-action';
import { ResolveLeftOverCapacityAction } from '@/modules/flight-actions/actions/cabin-actions/resolve-left-over-capacity-action';
import { SetAuBelowLafToZeroAction } from '@/modules/flight-actions/actions/cabin-actions/set-au-below-laf-to-zero-action';
import { SetPricingAdjustmentAction } from '@/modules/flight-actions/actions/cabin-actions/set-pricing-adjustment-action';
import { SetPricingIncrementAction } from '@/modules/flight-actions/actions/cabin-actions/set-pricing-increment-action';
import { SetPricingTacticAction } from '@/modules/flight-actions/actions/cabin-actions/set-pricing-tactic-action';
import { SetPromotionAction } from '@/modules/flight-actions/actions/cabin-actions/set-promotion-action';
import { SetRecommendedOverbookingRiskPercentageAction } from '@/modules/flight-actions/actions/cabin-actions/set-recommended-overbooking-risk-percentage-action';
import { SetSAofLAFAction } from '@/modules/flight-actions/actions/cabin-actions/set-seat-availability-of-lowest-available-fare-action';
import { AddNewSingleInventoryManagementContractAction } from '@/modules/flight-actions/actions/cabin-actions/single-inventory-management/add-new-contract-action';
import { MoveSingleInventoryManagementAllocationAction } from '@/modules/flight-actions/actions/cabin-actions/single-inventory-management/move-allocation-action';
import { MoveSingleInventoryManagementSrcLAFAction } from '@/modules/flight-actions/actions/cabin-actions/single-inventory-management/move-src-laf-action';
import { SetSingleInventoryManagementAllocationAction } from '@/modules/flight-actions/actions/cabin-actions/single-inventory-management/set-allocation-action';
import { SetSingleInventoryManagementSrcLAFAction } from '@/modules/flight-actions/actions/cabin-actions/single-inventory-management/set-src-laf-action';
import { ToggleSingleInventoryManagementContractStatusAction } from '@/modules/flight-actions/actions/cabin-actions/single-inventory-management/toggle-contract-status-action';
import { SwapOptimizationTacticAction } from '@/modules/flight-actions/actions/cabin-actions/swap-optimization-tactic-action';
import { UpdateAuthorizationUnitsAction } from '@/modules/flight-actions/actions/cabin-actions/update-authorization-units-action';
import { UpdateAutopilotAction } from '@/modules/flight-actions/actions/cabin-actions/update-autopilot-action';
import { UpdateClusterLevelAction } from '@/modules/flight-actions/actions/cabin-actions/update-cluster-level-action';
import { UpdateDiscontinueDateAction } from '@/modules/flight-actions/actions/cabin-actions/update-discontinue-date-action';
import { UpdateEffectiveDateAction } from '@/modules/flight-actions/actions/cabin-actions/update-effective-date-action';
import { UpdateMinControlAction } from '@/modules/flight-actions/actions/cabin-actions/update-min-control-action';
import { UpdateOverbookingAutopilotAction } from '@/modules/flight-actions/actions/cabin-actions/update-overbooking-autopilot-action';
import { UpdateProtectionAction } from '@/modules/flight-actions/actions/cabin-actions/update-protections-action';
import { UpdateSeatAvailabilityAction } from '@/modules/flight-actions/actions/cabin-actions/update-seat-availability-action';
import { UpdateSegmentLimitAction } from '@/modules/flight-actions/actions/cabin-actions/update-segment-limit-action';
import { AddTagsAction } from '@/modules/flight-actions/actions/definitions/add-tags-action';
import { RemoveTagsAction } from '@/modules/flight-actions/actions/definitions/remove-tags-action';
import { RevertToLastCaptureAction } from '@/modules/flight-actions/actions/flight-actions/revert-to-last-capture-action';
import { SendFlightToPssAction } from '@/modules/flight-actions/actions/flight-actions/send-flight-to-pss-action';
import { RemoveLinkedClassRuleAction } from '@/modules/flight-actions/actions/remove-linked-class-rule-action';
import { RemoveRivalRuleAction } from '@/modules/flight-actions/actions/remove-rival-rule-action';
import { RemoveSourceFlightAction } from '@/modules/flight-actions/actions/remove-source-flight-action';
import { UpdateSourceFlightAction } from '@/modules/flight-actions/actions/update-source-flight-action';
import { FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions.contracts';
import { logger } from '@/modules/monitoring';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';

export interface FlightActionConfigurationParams {
  pssCapabilities: UiAndApiPssCapabilities;
  inventoryConfigurationProperties: InventoryConfigurationProperties;
  priceIncrementRange: number[];
  flightLines?: SlimFlightLineModel[];
}
/**
 * flightActionMap holds a map of FlightActionTypes (from the API) that correspond to a certain FlightAction (in the UI).
 * This map makes sure we comply to the contract of the API and have a corresponding action for the type.
 */
const flightActionMap: Record<FlightActionType, (config: FlightActionConfigurationParams) => FlightAction<any>> = {
  [FlightActionType.tagsAdd]: () => new AddTagsAction(),
  [FlightActionType.addRivalRule]: () => new AddRivalRuleAction(),
  [FlightActionType.removeRivalRule]: () => new RemoveRivalRuleAction(),
  [FlightActionType.addLinkedClassRule]: () => new AddLinkedClassRuleAction(),
  [FlightActionType.removeLinkedClassRule]: () => new RemoveLinkedClassRuleAction(),
  [FlightActionType.updateClusterLevel]: () => new UpdateClusterLevelAction(),
  [FlightActionType.removeCluster]: () => new RemoveClusterAction(),
  [FlightActionType.removeSourceFlight]: () => new RemoveSourceFlightAction(),
  [FlightActionType.updateSourceFlight]: () => new UpdateSourceFlightAction(), // No value renderer
  [FlightActionType.changeOverbookingFactor]: () => new ChangeSingleLegOverbookingFactorAction(),
  [FlightActionType.changeMultiLegOverbookingFactor]: () => new ChangeMultiLegOverbookingFactorAction(),
  [FlightActionType.updateAutopilot]: () => new UpdateAutopilotAction(),
  [FlightActionType.setSaOfLaf]: () => new SetSAofLAFAction(), // No value renderer
  [FlightActionType.applyRecommendedAU]: () => new ApplyRecommendedAUAction(),
  [FlightActionType.removePromotion]: () => new RemovePromotionAction(), // No value renderer
  [FlightActionType.changeMinControl]: (config) => new UpdateMinControlAction({ config }), // No value renderer
  [FlightActionType.changeEffectiveDate]: (config) => new UpdateEffectiveDateAction({ config }), // No value renderer
  [FlightActionType.changeSegmentLimit]: (config) => new UpdateSegmentLimitAction({ config }), // No value renderer
  [FlightActionType.changeDiscontinueDate]: (config) => new UpdateDiscontinueDateAction({ config }), // No value renderer
  [FlightActionType.revert]: () => new RevertToLastCaptureAction(),
  [FlightActionType.closeLowestAvailableFare]: () => new MoveLowestAvailableFareUpAction(),
  [FlightActionType.SendFlightsToPss]: () => new SendFlightToPssAction(),
  [FlightActionType.moveClusterLevel]: () => new MoveClusterLevelAction(),
  [FlightActionType.openSetSeatsLowestAvailableFare]: (config) => new MoveLowestAvailableFareDownAction({ config }),
  [FlightActionType.setAuBelowLafToZero]: () => new SetAuBelowLafToZeroAction(),
  [FlightActionType.updateAuthorizationUnits]: () => new UpdateAuthorizationUnitsAction(),
  [FlightActionType.correctAUStructure]: () => new CorrectAuStructureAction(),
  [FlightActionType.updateProtection]: () => new UpdateProtectionAction(),
  [FlightActionType.updateSeatAvailability]: () => new UpdateSeatAvailabilityAction(),
  [FlightActionType.tagsRemove]: () => new RemoveTagsAction(),
  [FlightActionType.setPricingTactic]: () => new SetPricingTacticAction(), // No value renderer
  [FlightActionType.moveTacticalAdjustmentLevel]: () => new MoveTacticalAdjustmentLevelAction(),
  [FlightActionType.setPricingIncrement]: (config) => new SetPricingIncrementAction(config.priceIncrementRange[0]),
  [FlightActionType.increaseSaOfLaf]: (config) => new IncreaseSeatAvailabilityLowestAvailableFareAction({ config }),
  [FlightActionType.resolveByMatchingOvb]: () => new ResolveLeftOverCapacityAction(undefined, FlightActionType.resolveByMatchingOvb),
  [FlightActionType.resolveByMatchingUnbalancedAdjustment]: () =>
    new ResolveLeftOverCapacityAction(undefined, FlightActionType.resolveByMatchingUnbalancedAdjustment),
  [FlightActionType.resolveByIgnoring]: () => new ResolveLeftOverCapacityAction(undefined, FlightActionType.resolveByIgnoring),
  [FlightActionType.applyDatascienceRecommendedLaf]: () => new ApplyDatascienceRecommendedLafAction(), // No value renderer
  [FlightActionType.changeMultiLegBalancedAdjustment]: () => new ChangeMultiLegBalancedAdjustmentAction(),
  [FlightActionType.setPricingAdjustment]: () => new SetPricingAdjustmentAction(), // No value renderer
  [FlightActionType.moveOptimizationProfileLevel]: () => new MoveOptimizationProfileLevelAction(),
  [FlightActionType.moveShadowOptimizationProfileLevel]: () => new MoveShadowOptimizationProfileLevelAction(),
  [FlightActionType.addOptimisationProfileLevel]: () => new AddOptimizationProfileLevelAction(),
  [FlightActionType.removeOptimisationProfileLevel]: () => new AddOptimizationProfileLevelAction(),
  [FlightActionType.addShadowOptimisationProfileLevel]: () => new AddShadowOptimizationProfileLevelAction(),
  [FlightActionType.removeShadowOptimisationProfileLevel]: () => new AddShadowOptimizationProfileLevelAction(),
  [FlightActionType.swapOptimisationTactic]: () => new SwapOptimizationTacticAction(),
  [FlightActionType.matchCompetitor]: () => new MatchCompetitorAction(),
  [FlightActionType.changeMultiLegUnbalancedAdjustment]: () => new ChangeMultiLegUnbalancedAdjustmentAction(),
  [FlightActionType.changeBalancedAdjustment]: () => new ChangeBalancedAdjustmentAction(), // No value renderer
  [FlightActionType.changeUnbalancedAdjustment]: () => new ChangeUnbalancedAdjustmentAction(), // No value renderer
  [FlightActionType.setPromotion]: () => new SetPromotionAction(), // No value renderer,
  [FlightActionType.setRecommendedOverbookingRiskPercentage]: () => new SetRecommendedOverbookingRiskPercentageAction(),
  [FlightActionType.setMultiLeggedRecommendedOverbookingRiskPercentage]: () => new SetRecommendedOverbookingRiskPercentageAction(),
  [FlightActionType.applyRecommendedOverbookingFactor]: () => new ApplyRecommendedOverbookingFactorAction(),
  [FlightActionType.applyMultiLeggedRecommendedOverbookingFactor]: () => new ApplyRecommendedOverbookingFactorAction(),
  [FlightActionType.updateOverbookingAutopilot]: () => new UpdateOverbookingAutopilotAction(),
  [FlightActionType.updateMultiLeggedOverbookingAutopilot]: () => new UpdateOverbookingAutopilotAction(),
  [FlightActionType.moveRecommendedOverbookingRiskPercentage]: () => new MoveRecommendedOverbookingRiskPercentageAction(),
  // Single Inventory Management Actions
  [FlightActionType.setSingleInventoryManagementSrcLaf]: () => new SetSingleInventoryManagementSrcLAFAction(),
  [FlightActionType.moveSingleInventoryManagementSrcLafUp]: () =>
    new MoveSingleInventoryManagementSrcLAFAction(FilterFieldField.moveSrcLafUp),
  [FlightActionType.moveSingleInventoryManagementSrcLafDown]: () =>
    new MoveSingleInventoryManagementSrcLAFAction(FilterFieldField.moveSrcLafDown),
  [FlightActionType.setSingleInventoryManagementAllocation]: () => new SetSingleInventoryManagementAllocationAction(),
  [FlightActionType.moveSingleInventoryManagementAllocationUp]: () =>
    new MoveSingleInventoryManagementAllocationAction(FilterFieldField.moveAllocationUp),
  [FlightActionType.moveSingleInventoryManagementAllocationDown]: () =>
    new MoveSingleInventoryManagementAllocationAction(FilterFieldField.moveAllocationDown),
  [FlightActionType.toggleSingleInventoryManagementContractStatus]: () => new ToggleSingleInventoryManagementContractStatusAction(),
  [FlightActionType.addNewSingleInventoryManagementContract]: () => new AddNewSingleInventoryManagementContractAction(),
};

/**
 * FlightActionFactory provides new instances of FlightActions
 */
export class FlightActionFactory {
  /**
   * getAction generates a new FlightAction for the type provided.
   * @param type the type of the action to return
   * @param payload optionally, set the action with the values from the payload
   * @param config set the config params
   * @returns the FlightAction corresponding to the type, optionally filled with the values provided by the `payload` parameter
   */
  public static getAction({
    type,
    payload,
    config,
  }: {
    type: FlightActionType;
    payload?: any;
    config: FlightActionConfigurationParams;
  }): FlightAction<any> {
    if (!flightActionMap[type]) {
      logger.error(new Error(`No action found for type: ${type}`));
    }
    const action = flightActionMap[type](config);

    if (payload && action?.setPayload) action.setPayload(payload);

    return action;
  }
}
