export enum ControlFilterType {
  departure_date_range = 'departureDateRange',
  day_of_week = 'dayOfWeek',
  tags = 'tags',
  users = 'users',
  origin_destination_hub = 'originDestinationHub',
  flightNumber = 'flightNumber',
  aircraft_type = 'aircraftType',
  route_group = 'routeGroup',
  carrier_code = 'carrierCode',
  optimization_profile = 'optimizationProfile',
  optimization_tactic = 'optimizationTactic',
  autopilot = 'autopilot',
  cluster = 'cluster',
  eventName = 'eventName',
  eventCluster = 'eventCluster',
  stops = 'stops',
  to_allotment = 'toAllotment',
  to_contract_type = 'toContractType',
  to_contract_name = 'toContractName',
}
