<template>
  <sticky-header>
    <template #title>
      <k-bread-crumb :crumbs="visibleBreadcrumbs" />
    </template>

    <template #menu>
      <el-button
        v-if="isOnLinkedClassRuleRoute && hasLinkedClassRuleEditPermission"
        type="success"
        data-test="linked-class-rules-create-button"
        @click="navigateToCreateNewLinkedClassRule"
      >
        {{ $t('linked_class_rules.new_linked_class_rule') }}
      </el-button>

      <el-button
        v-if="isOnRivalRuleRoute && hasRivalRuleEditPermission"
        type="success"
        data-test="rival-rules-create-button"
        @click="navigateToCreateNewRivalRule"
      >
        {{ $t('rival_rules.new_rival_rule') }}
      </el-button>
    </template>

    <template #content>
      <k-tabs class="mb-8" data-test="rules-overview">
        <k-tabs-router-link
          v-if="hasLinkedRulesEnabled"
          :route-options="{ name: RouteName.linkedClassRulesOverview }"
          data-test="tabs-overview-linked-class-rules"
        >
          {{ $t('linked_class_rules.linked_class') }}
        </k-tabs-router-link>

        <k-tabs-router-link
          v-if="hasRivalRulesEnabled"
          :route-options="{ name: RouteName.rivalRulesOverview }"
          data-test="tabs-overview-rival-rules"
        >
          {{ $t('rival_rules.rival_rules') }}
        </k-tabs-router-link>
      </k-tabs>

      <router-view />
    </template>
  </sticky-header>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Router, onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { authService } from '@/modules/api/auth/auth-service';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { rulesRedirect } from '@/modules/rules/routes';
import { BreadCrumbInterface, RouteName } from '@/modules/shared';
import KBreadCrumb from '@/modules/shared/components/KBreadCrumb.vue';
import StickyHeader from '@/modules/shared/components/layouts/StickyHeader.vue';
import KTabs from '@/modules/shared/components/tabs/KTabs.vue';
import KTabsRouterLink from '@/modules/shared/components/tabs/KTabsRouterLink.vue';
import { useUserStore } from '@/modules/user-settings/store/user.store';

const route = useRoute();
const router: Router = useRouter();
const { t } = useI18n();

const userStore = useUserStore();
const { authorities } = storeToRefs(userStore);

const rivalRuleBreadcrumbs: BreadCrumbInterface[] = [
  {
    displayName: t('rules.rules'),
    to: { name: RouteName.rulesOverview },
  },
  {
    displayName: t('rival_rules.rival_rules'),
    to: { name: RouteName.rivalRulesOverview },
  },
];

const linkedClassBreadcrumbs: BreadCrumbInterface[] = [
  {
    displayName: t('rules.rules'),
    to: { name: RouteName.rulesOverview },
  },
  {
    displayName: t('linked_class_rules.linked_class'),
    to: { name: RouteName.linkedClassRulesOverview },
  },
];

const navigateToCreateNewLinkedClassRule = (): void => {
  router.push({ name: RouteName.linkedClassRulesCreate });
};

const navigateToCreateNewRivalRule = (): void => {
  router.push({ name: RouteName.rivalRulesCreate });
};

const customerSettingsStore = useCustomerSettingsStore();
const { settings } = storeToRefs(customerSettingsStore);

const hasLinkedRulesEnabled = computed(() => !!settings.value?.hasLinkedClassRulesEnabled);
const hasRivalRulesEnabled = computed(() => !!settings.value?.hasRivalRulesEnabled);

const isOnLinkedClassRuleRoute = computed(() => route.name === RouteName.linkedClassRulesOverview);
const isOnRivalRuleRoute = computed(() => route.name === RouteName.rivalRulesOverview);
const hasLinkedClassRuleEditPermission = computed(() => authService.hasAuthority([Authority.LinkedClassRulesUpdate], authorities.value));
const hasRivalRuleEditPermission = computed(() => authService.hasAuthority([Authority.RivalRulesUpdate], authorities.value));

const visibleBreadcrumbs = computed((): BreadCrumbInterface[] =>
  route.name === RouteName.rivalRulesOverview ? rivalRuleBreadcrumbs : linkedClassBreadcrumbs,
);

// Before Route Enter checks when you directly go to /rules or refresh
defineOptions({
  beforeRouteEnter: rulesRedirect,
});

// Before Route Update checks when you navigate within the /rules sub-routes (eg. linked-class-rules -> rules-overview)
onBeforeRouteUpdate(rulesRedirect);
</script>
