export const en = {
  flight_actions_module: {
    changes: 'no changes | one change | {count} changes',
    labels: {
      offset: 'Offset:',
      seats_available: 'Seats available:',
      distribution: 'Distribution:',
      apply_raus: 'Apply rAUs',
    },
    values: {
      maintainPr: 'Maintain protections',
      noMaintainPr: "Don't maintain protections",
      evenly: 'Evenly',
    },
    remove_all_tags: 'Remove All Tags',
    match_competitor_fare: {
      set_laf: 'Set LAF',
      to: 'to',
      than: 'than',
      with_a: 'With a',
      with_an: 'With an',
      price_adjustment_type_of: 'price adjustment type of',
      example_when: 'Example: When ',
      example_is: ' is ',
      example_select_laf: 'Select the LAF that is ',
      example_target_published_fare_price: 'the target published fare price of ',

      action_move_laf_down: "If this action moves a flight's LAF down",
      action_move_laf_up: "If this action moves a flight's LAF up",

      laf_boundaries: 'LAF boundaries',
      use_laf_boundaries: 'Use LAF boundaries',
      laf_boundaries_description: 'If the LAF would be set outside the boundaries, the LAF will be set to the outer boundary',
      laf_boundaries_no_limit: 'Do not use LAF boundaries',
      laf_boundaries_absolute_limit: 'Limit LAF moving beyond a specific price',
      laf_boundaries_class_limit: 'Limit LAF moving beyond a specific class',
      do_not_go_lower_than: 'Do not go lower than',
      do_not_go_higher_than: 'Do not go higher than',
      class: 'Class',

      set_seats: 'Set available seats',
      distribution: 'Distribution',

      closest: 'Closest',
      one_class_higher: 'Closest yet higher',
      one_class_lower: 'Closest yet lower',
      relative: 'Relative',
      absolute: 'Absolute',
      min_cf_short: 'Min CF',
      min_rival_fare_short: 'Min Rival CF',
      lowest_of_specific_carrier: 'Lowest of specific carrier',
      set_seats_and_distribution: 'Set available seats to {seats} and distribution to {distribution}.',
    },
    cabin_actions: {
      single_inventory_management_actions: {
        tour_operators: 'Tour Operators',
        tour_operator_select_tooltip: 'You can choose only tour operators that have been active in the last 60 days',
        fare_grid: 'Fare Grid',
        src_laf: 'srcLAF',
        allocation: 'Allocation',
        offset: 'Offset',
        set_src_laf_action: {
          set_src_laf: 'Set srcLAF',
          reset_offset: 'Reset Offset',
        },
        move_scr_laf_action: {
          move_src_laf_up: 'Move srcLAF Up',
          move_src_laf_down: 'Move srcLAF Down',
          move_up: 'Move Up',
          move_down: 'Move Down',
        },
        set_allocation_action: {
          set_allocation: 'Set Allocation',
        },
        move_allocation_action: {
          move_allocation_up: 'Move Allocation Up',
          move_allocation_down: 'Move Allocation Down',
          move_up: 'Move Up',
          move_down: 'Move Down',
        },
        toggle_contract_status_action: {
          toggle_contract_status: 'Toggle Contract Status',
          status: 'Status',
        },
        add_new_contract_action: {
          add_new_contract: 'Add New Contract',
        },
      },
    },
  },
};
